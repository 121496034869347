'use strict';

$(function(){
	$('.news__option__list').on('change',function(){
		var link = $(this).val();
		if(link != "") {
			location.href=link;
		}
	});
});
