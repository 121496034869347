'use strict';

$(function(){

    var $win = $(window);
    var $doc = $(document);
    var $body = $('body');

    //SPメニュー
    var $headerButton = $('.sp__btn');
    var $globalNavigation = $('.header__gnav');
    var isActive = 'is-active';
    var isOpen = 'is-open';
    var flag = false;

    $headerButton.on('click', function () {
        var $this = $(this);

        if (flag === false) {
        $body.addClass(isActive);
        $this.next().addClass(isOpen);
        $this.addClass(isActive).addClass(isActive);

        flag = true;
        } else {
        $body.removeClass(isActive);
        $this.next().removeClass(isOpen);
        $this.removeClass(isActive).removeClass(isActive);

        flag = false;
        }
    });

    $win.on('customMatchMedia', function (event, bool) {
        if (!bool) {
        //for pc
        $body.removeClass(isActive);
        $globalNavigation.removeAttr('style').removeClass(isOpen);
        $headerButton.removeClass(isActive).removeClass(isActive);
        } 
    });

    //ドロップダウン
    var $jsDropdown = $('.js-doropdown');
    var $jsDropdownIcon = $('.js-doropdown > .icon');
    var $navPanel = $('.navpanel');
    var isActive = 'is-active';


    var megadrop = function megadrop() {
        $jsDropdown.on('mouseover', function () {
          var $this = $(this);

          $this.addClass(isActive).find($navPanel).css({
            'height': '60px'
          });
        });
        $jsDropdown.on('mouseleave', function () {
          var $this = $(this);
          $this.removeClass(isActive).find($navPanel).css({
            'height': '0'
          });
        });
    };

    var navtoggle = function navtoggle() {
      $jsDropdownIcon.on('click', function () {
        var $this = $(this);

        if ($this.hasClass(isActive)) {
          $this.removeClass(isActive).next().stop().slideUp('fast');
        } else {
          $this.addClass(isActive).next().stop().slideDown('fast');
        }
      });
    };

    navtoggle();

    if ($win.width() > 1025) {
        //for pc
        megadrop();
        $navPanel.removeAttr('style');
      } else {
        //for sp
        $jsDropdown.off('mouseover mouseleave');
        $navPanel.css('height', 'auto');

    }

    $win.on('customMatchMedia', function (event, bool) {
        if (!bool) {
          //for pc
          megadrop();
          $navPanel.removeAttr('style');
          
        } else {
          //for sp
          $jsDropdown.off('mouseover mouseleave');
          $navPanel.css('height', 'auto');
        }
    });
      
    //トグル
    var $jsToggle = $('.js-toggle');

    $jsToggle.children().children().on('click', function(){
        $(this).toggleClass('is-open').next().slideToggle();
    });

    //serachページでフォームのcheckboxをリセット
    $('.js-reset-btn').on('click', function(){
      $("input[type='checkbox']").removeAttr("checked").prop("checked", false);
    });
    

    //スムーススクロール
    const headerHeight = 60;

    $('a[href^="#"]').click(function(){
        const href= $(this).attr("href");
        const target = $(href == "#" || href == "" ? 'html' : href);
        const position = target.offset().top - headerHeight;

        $("html, body").animate({scrollTop:position}, 550, "swing");
            return false;
    })

    //SP下部ボタン
    $(window).on("scroll",function(){
        // スクロール量
        var Hscroll = window.pageYOffset;

        // フッターのY座標
        var btnAreaY = $(".footer").offset().top;

        // フッターより上の場合
        if((btnAreaY > Hscroll + window.innerHeight)){
            $(".footer__sp-only__btn").slideDown("fast");
        }
        else{
            $(".footer__sp-only__btn").slideUp("fast");
        }
	});



    var mediaQueryList = window.matchMedia('(max-width: 1023px)');

    // イベントリスナの定義
    function mediaChange(mql) {
        // カスタムイベントを呼び出す
        $win.trigger('customMatchMedia', [mql.matches]);
    }
    // MediaQueryListにイベントリスナを登録
    mediaQueryList.addListener(mediaChange);

    // 初期状態の評価のためイベントリスナを一度実行
    mediaChange(mediaQueryList);
});